.what-we-offer-container {
    max-width: 1200px; /* Overall container width */
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    color: #333;
    font-family: var(--font-family);
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap onto multiple lines if necessary */
    justify-content: center; /* Center items horizontally */
    gap: 20px; /* Space between items */
  }
  
  .what-we-offer-container h2 {
    width: 100%; /* Full width for the heading */
    text-align: center;
    font-size: 1.8rem; /* Slightly smaller font size */
    color: #6D4C41; /* A rich, warm brown */
    margin-bottom: 15px;
  }
  
  .what-we-offer-container p {
    width: 100%; /* Full width for the paragraph */
    text-align: center;
    font-size: 1rem; /* Slightly smaller font size */
    margin-bottom: 20px;
    color: #555;
  }
  
  .service-category {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px; /* Adjust width for smaller images */
  }
  
  .service-image {
    width: 100%; /* Ensure the image fills its container */
    height: 0;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative; /* For positioning inside the container */
  }
  
  .service-category img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .service-category h3 {
    font-size: 1rem; /* Smaller font size */
    color: #8D6E63; /* A medium brown color */
    margin-top: 10px;
    text-align: center;
  }
  
  /* Responsive Styling for Laptops */
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .what-we-offer-container {
      padding: 20px;
    }
  
    .service-category {
      width: 150px; /* Adjust width for laptop screens */
    }
  
    .service-image {
      height: 120px; /* Adjust height for laptops */
      padding-top: 120px;
    }
  }
  
  /* Responsive Styling for Phones */
  @media screen and (max-width: 768px) {
    .what-we-offer-container {
      padding: 15px;
      flex-direction: column; /* Stack items vertically on small screens */
      align-items: center; /* Center items horizontally */
    }
  
    .service-category {
      width: 100%; /* Full width for each item on small screens */
      max-width: 300px; /* Maximum width for large phone screens */
      margin-bottom: 20px;
    }
  
    .service-image {
      height: 200px; /* Adjust height for phones */
      padding-top: 200px; /* Maintain aspect ratio */
    }
  }
  