.main_banner {
    padding-top: 1rem;
}

.home_container {
    margin-top: 4rem;
    width: 100vw;
}

.home_img {
    background-size: cover;
    width: 100%;
}