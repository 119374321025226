.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    max-width: 40vw; /* Base size for modal */
    max-height: 40vw; /* Base size for modal */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Media query for laptops */
  @media (max-width: 1024px) {
    .modal-content {
      max-width: 50vw; /* Larger modal for laptops */
      max-height: 50vw; /* Larger modal for laptops */
    }
  }
  
  /* Media query for tablets */
  @media (max-width: 768px) {
    .modal-content {
      max-width: 60vw; /* Larger modal for tablets */
      max-height: 60vw; /* Larger modal for tablets */
    }
  }
  
  /* Media query for phones */
  @media (max-width: 480px) {
    .modal-content {
      max-width: 80vw; /* Larger modal for phones */
      max-height: 80vw; /* Larger modal for phones */
    }
  }
  
  .modal-image {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    border-radius: 8px;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #333;
  }
  