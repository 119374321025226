.gallery-container {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .intro-section {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .intro-section h1 {
    font-size: 2.5rem;
    color: #000000;
  }
  
  .intro-section p {
    font-size: 1.2rem;
    color: #000000;
  }
  
  .gallery-preview {
    text-align: center;
  }
  
  .gallery-preview h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #000000;
  }
  
  .gallery-grid {
    display: grid; /* Use grid layout */
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    gap: 15px; /* Space between images */
    margin-bottom: 20px;
  }
  
  .gallery-grid img {
    width: 100%; /* Make the images fill the grid cells */
    aspect-ratio: 1 / 1; /* Maintain a square aspect ratio */
    object-fit: cover; /* Ensure images cover the container while maintaining their aspect ratio */
    border-radius: 8px;
    transition: transform 0.3s ease;
    cursor: pointer; /* Add cursor to indicate images are clickable */
  }
  
  .gallery-grid img:hover {
    transform: scale(1.05);
  }
  
  .view-more {
    display: inline-block;
    padding: 10px 20px;
    background-color: #000000;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .view-more:hover {
    background-color: #555;
  }
  
  /* Media Queries for Responsive Design */
  
  /* For tablets and larger phones */
  @media (max-width: 768px) {
    .gallery-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    }
  
    .intro-section h1 {
      font-size: 2rem; /* Smaller font size for tablets */
    }
  
    .intro-section p {
      font-size: 1rem; /* Smaller font size for tablets */
    }
  
    .gallery-preview h2 {
      font-size: 1.5rem; /* Smaller font size for tablets */
    }
  }
  
  /* For mobile devices */
  @media (max-width: 480px) {
    .gallery-grid {
      grid-template-columns: 1fr; /* Single column for mobile phones */
    }
  
    .intro-section h1 {
      font-size: 1.5rem; /* Smaller font size for mobile phones */
    }
  
    .intro-section p {
      font-size: 0.9rem; /* Smaller font size for mobile phones */
    }
  
    .gallery-preview h2 {
      font-size: 1.2rem; /* Smaller font size for mobile phones */
    }
  
    .view-more {
      padding: 8px 16px; /* Smaller padding for mobile phones */
    }
  }
  