.main-locations {
  padding: 6rem 0 2rem 0;
  background-color: rgba(203, 202, 202, 0.252);
}

.main-locations h2 {
  display: flex;
  justify-content: center;
  padding: 1.5rem;
}

#locations {
  display: flex;
  justify-content: space-evenly;
  padding: 1rem 0rem;
  flex-wrap: wrap;
  background-color: rgba(246, 251, 251, 0.234);
}

.h3 {
  display: flex;
  color: rgb(20, 19, 19);
  justify-content: center;
  text-transform: uppercase;
  font-size: 1.6rem;
}

.locations-details {
  margin-bottom: 20px;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* 
#contact-us li {
  padding-top: 1.5rem;
  list-style-type: none;
}

.icons {
  font-size: 1.8rem;
  margin: 0 6px;
}

a {
  color: black;
}

.icons:hover{
  color:var(--button-color);
} */

.maps-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

.location1-map,
.location2-map {
  margin-top: 10px;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
}


@media (max-width:991px) {
  .h3 {
      font-size: 1.4rem;
      font-weight: normal;
  }
}

@media (max-width:768px) {
  .h3 {
      font-size: 1.3rem;
  }

  h2 {
      font-size: 1.6rem;
  }

  .icons {
      font-size: 1.6rem;
  }
}

@media (max-width:578px) {
  .h3 {
      font-size: 1.1rem;
  }

  h2 {
      font-size: 1.4rem;
  }
}
