.make-appointment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #000;
  animation: fadeIn 1.5s ease-in-out; /* Container fade-in animation */
}

.make-appointment {
  display: inline-block; /* Ensures the container fits the content */
  padding: 15px 25px; /* Reduced padding for a tighter fit */
  background-color: #000; /* White background for text visibility */
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(255, 158, 3, 0.562);
  text-align: center;
  animation: popUp 1.5s ease-in-out; /* Popup effect for the container */
  transition: transform 0.3s ease-in-out; /* Smooth scaling on hover */
}

.make-appointment:hover {
  transform: scale(1.05); /* Slightly increase container size on hover */
}

.contact-info {
  font-size: 1.5rem; /* Moderate size for one-line fit */
  font-weight: 600; /* Slightly bold for emphasis */
  color: #fff; /* Darker text color for better contrast */
  font-family: 'Arial', sans-serif; /* A clean, modern font */
  letter-spacing: 0.5px; /* Adds subtle spacing between letters */
  white-space: nowrap; /* Prevents the text from wrapping to the next line */
  animation: fadeInText 2s ease-in-out; /* Text fade-in animation */
  transition: transform 0.3s ease-in-out; /* Smooth scaling on hover */
}

.contact-info:hover {
  transform: scale(1.05); /* Slightly increase text size on hover */
}

.contact-info a {
  color: #d67607; /* Highlight link color */
  text-decoration: none;
  font-size: 1.6rem; /* Slightly larger for the phone number */
  font-weight: bold;
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition on hover */
}

.contact-info a:hover {
  color: #323232; /* Darker on hover */
  text-decoration: underline;
  transform: scale(1.1); /* Slightly enlarge text on hover */
}

/* Container Fade-in animation */
@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

/* Pop-up animation for the container */
@keyframes popUp {
  0% {
      transform: scale(0.9); /* Start small */
      opacity: 0;
  }
  100% {
      transform: scale(1); /* End at normal size */
      opacity: 1;
  }
}

/* Text fade-in animation */
@keyframes fadeInText {
  0% {
      opacity: 0;
      transform: translateY(10px); /* Start slightly below */
  }
  100% {
      opacity: 1;
      transform: translateY(0); /* End at normal position */
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .make-appointment {
      padding: 15px; /* Adjust padding for smaller screens */
  }

  .contact-info {
      font-size: 18px; /* Slightly reduced for smaller screens */
  }

  .contact-info a {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .make-appointment {
      padding: 10px; /* Further reduced padding */
  }

  .contact-info {
      font-size: 16px; /* Adjust font size for mobile */
  }

  .contact-info a {
    font-size: 16px;
  }
}

@media (max-width: 391px) {
  .make-appointment {
      padding: 8px; /* Minimal padding for very small screens */
  }

  .contact-info {
      font-size: 14px; /* Minimal font size for very small screens */
  }

  .contact-info a {
    font-size: 14px;
  }
}
