.about-main {
    height: 425px;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center;     /* Center horizontally */
    text-align: center;      /* Center text within elements */

}

.about-content {
    position: relative;
    z-index: 3;
}

.about-heading {
    text-shadow: 0 4px 4px rgba(255, 255, 255, 0.25);
    text-align: center;
    font-size: 46px;
    line-height: 52px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 2px;
    margin: 0;
    padding-bottom: 15px;
    text-transform: uppercase;
    font-family: "Poppins", Sans-serif;
}

.about-path {
    margin-top: 10px; /* Reduced margin to decrease space */
    margin-bottom: 0; /* Remove bottom margin if needed */
}

* + .about-path {
    margin-top: 14px;
}

.about-path li {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    letter-spacing: .08em;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.7;
    color: rgba(255, 255, 255, 0.6);
}

.about-path > * {
    padding-left: 15px;
    padding-right: 15px;
}

.about-path a {
    display: inline;
    vertical-align: middle;
    color: #fff;
    text-decoration: none;

}

.about-path li::after {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: 52%;
    right: -5px;
    display: inline-block;
    background: #fff;
    transform: translate3d(0, -50%, 0);
}

.about-path li:last-child::after {
    display: none; /* Hide the dot for the last item */
}

@media (min-width: 576px) {
    * + .about-path {
        margin-top: 12px;
    }
}

@media (max-width: 991.98px) {
    .about-heading {
        font-size: 26px;
    }
}