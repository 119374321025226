@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gotu&display=swap');

:root{
  --primary-color:rgba(0, 0, 0, 0.785);
  --secondary-color:rgb(255, 255, 255);
  --button-color: #FF8C00;
  --font-family: 'Reem Kufi',sans-serif;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-family: var(--font-family);
  overflow-x: hidden;
  box-sizing: border-box;
}

h3{
  font-family: var(--font-family);
  color: rgb(0, 0, 0);
  font-size: 2rem;
  font-weight: 500;
}

html{
  box-sizing: border-box;
  scroll-behavior: smooth;
  height: 100%;
  overflow-x: hidden;
}

br {
  content: "";
  display: block;
  margin: 14px 0; /* Space above and below <br> */
}

::-webkit-scrollbar{
  background-color: var(--primary-color);
  width: 0.6rem;
  box-shadow: inset 1px 1px 8px 6px rgb(19, 18, 27);
}

::-webkit-scrollbar-thumb{
  background-color: var(--secondary-color);
  box-shadow: inset 10px 5px 6px -2px rgb(176, 171, 171);
  border-radius:1rem;
}

@media screen and (min-width:280px) and (max-width:1480px){
  body::-webkit-scrollbar{
      display: none;
  }
}
