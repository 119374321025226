/* Component styling within the main container */
.why-choose-us {
    width: 100%;
    max-width: 1200px; /* Limit the maximum width */
    text-align: center;
    margin-top: 60px; /* Margin added to push the section down */
    margin-bottom: 40px;
  }
  
  /* Styling for the introductory section */
  .intro {
    margin-bottom: 20px;
  }
  
  .intro h2 {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #000000;
  }
  
  .intro p {
    font-size: 1.2em;
    color: #000000;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Grid layout for reasons */
  .reasons {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3-column grid */
    gap: 20px;
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Individual reason card styling */
  .reason-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  /* Hover effect for reason cards */
  .reason-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Icon styling within each card */
  .icon {
    font-size: 3em;
    margin-bottom: 10px;
    color: #ff6600;
  }
  
  /* Title styling within each card */
  .reason-card h3 {
    font-size: 1.4em;
    margin-bottom: 10px;
    color: #000000;
  }
  
  /* Description styling within each card */
  .reason-card p {
    font-size: 1em;
    color: #000000;
  }
  
  /* Responsive design for phones */
  @media (max-width: 768px) {
    .reasons {
      grid-template-columns: 1fr; /* Single column layout on smaller screens */
      gap: 15px;
    }
  
    .intro h2 {
      font-size: 2em;
    }
  
    .intro p {
      font-size: 1em;
    }
  }
  
  /* Responsive design for tablets and small laptops */
  @media (min-width: 769px) and (max-width: 1024px) {
    .reasons {
      grid-template-columns: repeat(2, 1fr); /* Two columns on medium screens */
    }
  }
  