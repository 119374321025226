/* Outer container styling */
.outer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    background-color: #f0f0f0;
  }
  
  /* Inner container styling */
  .mission-values-container {
    background-color: #f0f0f0;
    border-radius: 12px;
    max-width: 900px;
    width: 100%;
    padding: 30px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .mission-values-container:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  /* Flexbox container for Mission and Vision */
  .mission-vision-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 30px;
  }
  
  .mission-section,
  .vision-section {
    flex: 1;
  }
  
  /* Mission, Vision, and Values section styling */
  .mission-section h2,
  .vision-section h2,
  .values-section h2 {
    color: #000000;
    font-size: 28px;
    margin-bottom: 15px;
    border-bottom: 2px solid #e5e5e5;
    padding-bottom: 10px;
  }
  
  .mission-section p,
  .vision-section p {
    font-size: 16px;
    color: #000000;
    line-height: 1.6;
  }
  
  /* Values section styling */
  .values-section {
    margin-top: 30px;
  }
  
  /* Values grid styling without scroll */
  .values-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    overflow: hidden; /* Removed scrolling */
    padding-right: 10px;
  }
  
  /* Value item styling */
  .value-item {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    opacity: 0;
    transform: translateX(100%);
    animation: slideIn 0.6s ease forwards;
  }
  
  /* Icon styling */
  .value-icon {
    font-size: 24px;
    color: #ff6b6b;
    margin-right: 15px;
  }
  
  /* Individual delay for each item for staggered effect */
  .value-item:nth-child(1) {
    animation-delay: 0.2s;
  }
  
  .value-item:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .value-item:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  .value-item:nth-child(4) {
    animation-delay: 0.8s;
  }
  
  .value-item:nth-child(5) {
    animation-delay: 1s;
  }
  
  .value-item:nth-child(6) {
    animation-delay: 1.2s;
  }
  
  .value-item:nth-child(7) {
    animation-delay: 1.4s;
  }
  
  /* Keyframes for sliding animation */
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .value-item strong {
    display: block;
    font-size: 18px;
    color: #222;
    margin-bottom: 5px;
  }
  
  .value-item p {
    font-size: 16px;
    color: #000000;
    line-height: 1.6;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    /* Stack Mission and Vision vertically on smaller screens */
    .mission-vision-row {
      flex-direction: column;
    }
  
    /* Adjust grid layout for tablets and large phones */
    .values-grid {
      grid-template-columns: 1fr;
    }
  
    .mission-values-container {
      padding: 20px;
    }
  
    .mission-section h2,
    .vision-section h2,
    .values-section h2 {
      font-size: 24px;
    }
  
    .value-item {
      padding: 15px;
    }
  
    .value-icon {
      font-size: 20px;
      margin-right: 10px;
    }
  
    .value-item strong {
      font-size: 16px;
    }
  
    .value-item p {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    /* Styling for phones */
    .mission-values-container {
      padding: 15px;
    }
  
    .mission-section h2,
    .vision-section h2,
    .values-section h2 {
      font-size: 22px;
    }
  
    .value-item {
      padding: 12px;
    }
  
    .value-icon {
      font-size: 18px;
      margin-right: 8px;
    }
  
    .value-item strong {
      font-size: 15px;
    }
  
    .value-item p {
      font-size: 13px;
    }
  }
  