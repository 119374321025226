.top-btn {
    font-size: 2rem;
    width: 3rem;
    height: 3rem;
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    position: fixed;
    bottom: 4rem; /* Ensure this value is appropriate for your design */
    right: 1rem; /* Ensure this value is appropriate for your design */
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow:
        0 0 4px rgb(223, 219, 219),
        0 0 4px rgb(223, 219, 219),
        0 0 4px rgb(223, 219, 219),
        0 0 4px rgb(223, 219, 219);
    box-sizing: border-box; /* Added for better sizing management */
}

/* Media queries for smaller screens */
@media (max-width:576px) {
    .top-btn {
        font-size: 1.8rem;
        width: 2.8rem;
        height: 2.8rem;
        bottom: 3.5rem;
        right: 1.9rem;
    }
}

@media (max-width:391px) {
    .top-btn {
        font-size: 1.6rem;
        width: 2.6rem;
        height: 2.6rem;
    }
}
