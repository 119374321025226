.main-service {
    position: relative;
    padding-top: 2rem;
    /*
    width: 100%;
    max-width: 1200px; /* Limit maximum width for better control
    margin: 0 auto;  /* Center align the container 
    */
}

.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    /*
    text-align: center;  /* Center align text in heading 
    margin-bottom: 2rem;  Add spacing below heading */
}

.service-imgs {
    display: flex; /* Use Flexbox to align images side by side */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    /*justify-content: space-between; /* Distribute space between images */
    /*gap: 1rem; /* Add spacing between images */
    width: 100%;
    /*margin-bottom: 1rem; */
    flex-flow: row;
}

.img1 { /* Ensure both images are sized equally */
    flex: 1 1 45%; /* Allow images to grow and shrink equally, with a base size of 45% */
    position: relative; /* Maintain relative positioning for absolute children */
    overflow: hidden; /* Hide any overflow */
}


.img1 img {
    width: 100%;
}

.span1 {
    color: goldenrod;
    display: flex;
    position: absolute;
    font-weight: 500;
    font-size: 3.4rem;
    top: 65%;
    left: 68%;
}

.span2 {
    color: rgb(19, 19, 19);
    display: flex;
    position: absolute;
    font-weight: 500;
    font-size: 3.4rem;
    top: 65%;
    right: 76%;
}

.span1:hover,
.span2:hover {
    scale: 1.1; /* Use transform for scaling */
    transition: all ease-in-out 1s;
}

@media (max-width: 1200px) {

    .span1, .span2 {
        font-size: 2.4rem;
        font-weight: 500;
    }
}

@media (max-width: 990px) {
    .span1, .span2 {
        font-size: 1.9rem;
    }

    .heading h2 {
        font-size: 1.8rem;
    }
}

@media (max-width: 767px) {

    .span1, .span2 {
        font-size: 1.7rem;
    }
}

@media (max-width: 576px) {
    .service-imgs {
        display: block; /* Allow single-column display */
    }

    .span1 {
        top: 35%;
        left: 67%;
        font-size: 1.5rem;
    }

    .span2 {
        top: 85%;
        right: 67%;
        font-size: 1.5rem;
    }

    .heading h2 {
        font-size: 1.6rem;
    }
}

@media (max-width: 391px) {
    .span1 {
        top: 35%;
        left: 67%;
        font-size: 1.3rem;
    }

    .span2 {
        top: 85%;
        right: 67%;
        font-size: 1.3rem;
    }

    .heading h2 {
        font-size: 1.5rem;
    }
}
