.policies-container {
    text-align: center;
    background-color: lightgrey; /* Semi-transparent purple background */
    padding: 20px;
    color: rgb(0, 0, 0);
  }
  
  .policies-header, .policies-footer {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Grid for larger screens */
  .policies-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four columns for larger screens */
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .policy {
    padding: 15px;
    border-radius: 10px;
    border: 2px solid white;
  }
  
  .policy-icon {
    width: 60px;
    height: 60px;
    color: #6D4C41; /* Or any color you'd prefer */
    margin-bottom: 15px;
  }
  
  .policy p {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .highlight {
    color: red;
    font-weight: bold;
  }
  
  /* Responsive Design for tablets and smaller devices */
  @media (max-width: 1024px) {
    .policies-header, .policies-footer {
      font-size: 1.8rem;
    }
    
    .policies-grid {
      grid-template-columns: repeat(2, 1fr); /* Two columns for tablets */
    }
  
    .policy p {
      font-size: 1.1rem;
    }
  }
  
  /* Responsive Design for mobile devices */
  @media (max-width: 768px) {
    .policies-header, .policies-footer {
      font-size: 1.2rem;
    }
    
    .policies-grid {
      grid-template-columns: 1fr; /* Single column for mobile devices */
    }
  
    .policy p {
      font-size: 1rem;
    }
  
    .policy-icon {
      width: 50px;
      height: 50px;
    }
  }
  