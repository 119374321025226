/* Base styles */
.container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .header {
    background-color: #c2b280; /* Beige background color */
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  
  .header h1 {
    margin: 0;
    font-size: 2.5em;
    color: #fff;
    text-align: center;
  }
  
  .main-content {
    background-color: #fff; /* White background for the main content */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .main-content p {
    font-size: 1.1em;
    line-height: 1.6;
    margin: 0 0 20px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .header h1 {
      font-size: 2em;
    }
  
    .main-content p {
      font-size: 1em;
    }
  }
  
  @media (max-width: 480px) {
    .header {
      padding: 15px;
    }
  
    .header h1 {
      font-size: 1.5em;
    }
  
    .main-content {
      padding: 15px;
    }
  
    .main-content p {
      font-size: 0.9em;
    }
  }
  