.price-list {
  /*background-color: #ffffff;  Rich teal background for the price list */
  background-image: url('../../../img/dark_wood.png');
  color: #000000; /* White text for contrast */
  padding: 20px;
  font-family: Arial, sans-serif;
  width: 100vw; /* Full width of the viewport */
  margin: 0 auto;
}

.heading {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  position:relative;
  margin-top: 80px;
  z-index: 999;
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 8px; /* Rounded corners for a softer look */
}

.ladies-gent-heading {
  color:#000000;
  font-size: 1.3rem;
  text-decoration: none;
}

.ladies-gent-heading:hover {
  color: #000000;
  text-decoration: none;
}

.heading:hover {
  background-color: #e3a01c;
}

.hero-banner {
  /* margin-top: 8em;  Add margin to the top of the hero banner */
  text-align: center;
  background-color: #e3a01c; /* Lighter teal for the hero banner */
  padding: 20px; /* Add padding for better spacing */
  border-radius: 8px; /* Rounded corners for a softer look */
}

.hero-banner h1,
.hero-banner h2,
.disclaimer {
  margin: 0;
}

.hero-banner h1 {
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
}

.hero-banner h2 {
  font-size: 1.5rem;
}

.disclaimer {
  margin-top: 10px;
  font-size: 1rem;
  font-style: italic;
}

.ladies-category {
  display: block; /* Single-column layout */
  text-transform: uppercase;
  margin: 0 auto;
}

.ladies-category p {
  font-size: 1.2rem;
  padding: 10px;
  background-color: #ffffff99; /* White background for service list */
  border: 2px solid #e3a01c; /* Accent color border for contrast */
  border-radius: 5px; /* Rounded corners for a polished appearance */
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
}

.ladies-category .price {
  font-weight: bold;
}

.kid-styles {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  position: relative; /* Make it a positioned container */
}

.kid-styles img {
  width: 50%; /* Keep the smaller size */
  max-width: 180px;
  position: absolute; /* Position the image absolutely */
  left: 70%; /* Set right position */
  transform: translateX(-50%) rotate(20deg); /* Center horizontally and tilt */
  top: 50%; /* Adjust the vertical position to align with the text */
}

/* Centering the text */
.kid-styles {
  text-align: center;
  margin-bottom: 10px; /* Space between the text and the image */
  z-index: 1; /* Ensure the text is above the image */
}

.kid-styles h3 {
  color: white;
}

.kid-styles p {
  font-size: 1.2rem;
  margin: 5px 0;
  display: flex;
  align-items: center;
  color: white;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .hero-banner h1 {
    font-size: 1.8rem;
  }

  .hero-banner h2 {
    font-size: 1.3rem;
  }

  .ladies-category p {
    font-size: 1rem;
  }

  .ladies-category p {
    flex-direction: column;
    text-align: center;
  }

  .ladies-category .price {
    margin-top: 5px;
  }

  .kid-styles {
    font-size: 1.2rem; /* Adjust font size for medium devices */
  }

  .kid-styles img {
    width: 20%; /* Increase image size for medium devices */
  }
}

@media (max-width: 480px) {
  .hero-banner h1 {
    font-size: 1.5rem;
  }

  .hero-banner h2 {
    font-size: 1.2rem;
  }

  .disclaimer {
    font-size: 0.8rem;
  }

  .ladies-category p {
    font-size: 0.9rem;
  }

  .ladies-category .price {
    font-size: 1rem;
  }

  .kid-styles {
    font-size: 1rem; /* Adjust font size for small devices */
  }

  .kid-styles img {
    width: 70%; /* Decrease the image size for small devices */
    max-width: 140px; /* Adjust max width for small devices */
    left: 80%;
  }
}
