#main-footer {
    position: relative;
    background-color: black;
    text-align: center;
}

.footer-img {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.img-logo {
    width: 5rem;
    margin-top: 15px;
}

.nav-grid {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
}

.nav-list {
    display: flex;
    list-style-type: circle;
    flex-wrap: wrap;
    gap: 2.5rem;
    font-size: 0.8rem;
    color: whitesmoke;
}

.nav-link {
    color:whitesmoke;
    text-decoration: none;
    text-transform: uppercase;
}

.nav-link:visited {
    display: inline-block;
    font-size: 0.7rem;
    transition: color 0.5 linear;
    text-decoration: none;
}

.nav-link:hover {
    color: gainsboro;
    text-decoration: none;
}

.social-grid {
    display: flex;
    justify-content: center;
    color: bisque;
    padding-right: 1.3rem;
}

.icons {
    display: flex;
    gap: 2rem;
    list-style-type: none;
    justify-content: center;
}

.icons-color{
    color: aliceblue;
    font-size: 1.5rem;
    transition:all ease-in-out 0.3s
}

#insta-icon:hover {
    color: rgb(221, 42, 123);
    transition: all ease-in-out 0.3s;
}

#facebook-icon:hover {
    color: rgb(24, 119, 242);
    transition: all ease-in-out 0.3s;
}

.text-grid {
    display: flex;
    justify-content: center;
    padding-left: 0.8rem;
    margin:20px;
}

.text-grid p {
    color: whitesmoke;
    font-size: 0.9rem;
    justify-content: center;
}

.footer-bottom {
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    color: aliceblue;
    padding-top: 0.5rem;
    font-size: 0.9rem;
}

@media (max-width: 576px) {
    .nav-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        font-size: 0.7rem;
        row-gap: 1.8rem;
        padding-left: 0px;
    }

    .social-grid {
        padding-right: 2.5rem;
    }

    .text-grid {
        padding-left: 0px;
    }

    .footer-bottom {
        font-size: 0.8rem;
    }
}

@media (max-width: 391px) {
    #main-footer {
        padding-top: 0px;
    }
}