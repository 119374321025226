#mainHeader {
    padding: 0 2rem;
    top: 0;
    background-color: var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 5rem;
}

.imgLogo {
    height: 4rem;
    float: left;
}