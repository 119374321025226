#main_nav {
    box-sizing: border-box;
}

.nav_list {
    display: flex;
    gap: 3.4rem;
    list-style: none;
    transition: 0.9s linear;
}

.nav_list .nav_link {
    color: whitesmoke;
    text-decoration: none;
    text-transform: uppercase;
}

.nav_link:visited {
    display: inline-block;
    font-size: 1rem;
}

.nav_link:hover{
    color: var(--button-color);
    border-bottom: 1px solid var(--button-color);
    transition: ease-in-out 0.2s;
}

#link_dropdown {
    color: whitesmoke;
    text-decoration: none;
    text-transform: uppercase;
}

#link_dropdown:hover {
    color: var(--button-color);
    border-bottom: 1px solid var(--button-color);
    transition: ease-in-out 0.2s;
}

#link_gentlemen, #link_ladies {
    color: rgb(4, 4, 5);
}

.mobile_nav_button {
    display: none;
    color: whitesmoke;
}

#close_outline {
    display: none;
}

.mobile_nav_button[name="close-outline"] {
    display: none;
}

@media screen and (max-width: 867px) {
    #link_gentlemen, #link_ladies {
        display: flex;
        justify-content: center;
        color: rgb(4, 4, 5);
        font-size: 0.8rem;
    }

    #link_dropdown {
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 2px;
    }

    #close_outline, .mobile_nav_icon {
        font-size: 1.8rem;
    }

    .nav_link {
        font-size: 1rem;
    }

    .mobile_nav_button {
        display: inline-block;
        z-index: 999;
    }

    .nav_list {
        width: 100vw;
        height: 29rem;
        position: absolute;
        top: 0;
        left: 0;
        background-color: black;
        flex-direction: column;
        display: flex;
        padding-left: 3rem;
        justify-content: center;
        align-items: start;
        transform: translateX(100%);
        transform: none;
        visibility: hidden;
        opacity: 0;
        text-align: center;
        text-decoration: none;
        border-radius: 10px;
        box-shadow: 2px 3px 6px 5px rgba(0, 0, 0, 0.603);
    }

    .active .mobile_nav_icon {
        display: none;
        font-size: 2rem;
        position: absolute;
        top: 1.9rem;
        right: 2.3rem;
        z-index: 999;
    }

    .active #close_outline {
        display: inline-block;
        color: aliceblue;
    }

    .active .nav_list {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
        z-index: 999;
    }
}

/* Extra Small Devices (screen width <= 500px) */
@media screen and (max-width: 500px) {
    .nav_link {
        font-size: 0.9rem;
        margin-bottom: 1.2rem;
        color: white;
    }

    .mobile_nav_button {
        font-size: 1.8rem;
    }

    #close_outline {
        font-size: 1.8rem;
    }

    .nav_list {
        padding-left: 2rem;
    }
}