/* Main Banner Section */
.main_banner {
    padding-top: 1rem;
}

/* Swiper container adjustments */
.home_container {
    margin-top: 4rem;
    width: 100vw;
}

/* Adjust banner image to be more subtle */
.home_img {
    width: 100%;
    height: 470px; /* Keep banner height */
    object-fit: cover;
    border-radius: 5px;
    filter: brightness(70%); /* Darken the image to match overall theme */
}

.gent-heading {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    position:relative;
    margin-top: 10px;
    z-index: 999;
    width: 100%;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 8px; /* Rounded corners for a softer look */
}
  
.ladies-gent-heading {
    color:#000000;
    font-size: 1.3rem;
    text-decoration: none;
}
  
.ladies-gent-heading:hover {
    color: #000000;
    text-decoration: none;
}
  
.gent-heading:hover {
    background-color: #e3a01c;
}

/* Gentlemen Services Section */
.gentlemen-container {
    padding-top: 1rem;
    /* margin-bottom: 20px; */
    background-image: url('../../../img//dark_wood.png'); /* Ensure correct path */
    min-height: 100vh; /* Full viewport height */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;
    display: flex;
    flex-direction: column;
    /*background-color: rgba(0, 0, 0, 0.7); /* Add dark transparent background */
}

/* Heading style */
.gentlemen-container h1 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #f7f7f7;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    text-align: center;
}

/* Service list layout */
.gentlemen-list {
    padding: 20px 0;
}

/* Gentlemen services individual item */
.gentlemen-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #444;
    padding: 15px 0;
    font-size: 1.2rem;
    font-weight: 500;
}

/* Remove border for the last item */
.gentlemen-item:last-child {
    border-bottom: none;
}

/* Styling for service name and price */
.service-name {
    color: #f7f7f7;
}

.service-price {
    color: #f7c35c; /* Gold color for price */
    font-weight: bold;
}

/* Contact information styling */
.contact {
    margin-top: 40px;
    font-size: 1.2rem;
    color: #f7f7f7;
    text-align: center;
}

.contact strong {
    font-size: 1.5rem;
    color: #f7c35c;
}

/* Footer Styling (to match the bottom section of the second attachment) */
footer {
    background-color: #000;
    color: #f7f7f7;
    padding: 20px 0;
    text-align: center;
}

footer a {
    color: #f7c35c;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .gentlemen-container h1 {
        font-size: 2rem;
    }

    .gentlemen-item {
        font-size: 1.1rem;
    }

    .contact {
        font-size: 1.1rem;
    }

    /* Adjust Swiper image size on mobile */
    .home_img {
        height: 150px;
    }
}
